import { HeroSlideProps } from '@sbercloud/uikit-product-site-hero/dist/esm/components/HeroSlider/components';

import { CustomHeroSlide } from './types';

export const CUSTOM_SLIDES_MAIN: (CustomHeroSlide & {
  format: (HeroSlideProps['media'] & { type: 'image' })['format'];
})[] = [
  {
    customAnalyticsId: 'main-custom-marketing-slide-button',
    order: '0',
    id: 'main-custom-marketing-slide',
    title: 'Есть где<br /> развернуться',
    description: 'Делаем доступ к облакам и AI простым&nbsp;и&nbsp;удобным',
    button: {
      link: '',
      text: 'Попробовать бесплатно',
      consoleUrl: true,
    },
    image: {
      desktop:
        'https://cdn.cloud.ru/backend/carousel/main-carousel/main_custom_slider_first_desktop.webp',
      tablet:
        'https://cdn.cloud.ru/backend/carousel/main-carousel/main_custom_slider_first_tablet.webp',
    },
    theme: '#26d07c',
    format: 'rectangle',
  },
];
