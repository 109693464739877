'use client';

import {
  AdminSVG,
  CloudsSVG,
  DatacenterHostSVG,
  FileSVG,
  HeadphonesMicrophoneSVG,
  RocketSVG,
  RubleSVG,
  SecuritySVG,
} from '@sbercloud/uikit-product-icons';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { Seo } from 'src/components/Seo';
import { TariffPlans } from 'src/components/TariffPlans';
import { consoleRedirectUrl } from 'src/global';
import { useFormatConsoleUrl } from 'src/hooks/useFormatConsoleUrl';
import { Layout } from 'src/layout';
import { organizationMicroData } from 'src/scripts';
import { type Catalog } from 'src/types/backendContent';
import { Benefits } from 'src/uikit/Benefits';
import { ClientCasesProps } from 'src/uikit/Carousels/CarouselBase/slides/ClientCasesSlide';
import { ClientCasesCarousel } from 'src/uikit/Carousels/ClientCasesCarousel';
import { MainCarousel, MainSlideProps } from 'src/uikit/Carousels/MainCarousel';
import { CatalogBlock } from 'src/uikit/CatalogBlock';
import { DemoSection } from 'src/uikit/DemoSection';
import { Form } from 'src/uikit/Form';

import s from './Index.module.scss';

const BENEFITS = {
  title: 'Почему нас выбирают',
  items: [
    {
      icon: RocketSVG,
      title: 'Все для быстрого старта',
      text: 'Понятная консоль управления сервисами, подробные руководства и документация с пошаговыми инструкциями.',
    },
    {
      icon: CloudsSVG,
      title: 'Большой выбор сервисов',
      text: 'Инструменты для разработки и хостинга микросервисных приложений, вычислений, аналитики данных, два суперкомпьютера для работы с AI.',
    },
    {
      icon: SecuritySVG,
      title: 'Безопасность данных',
      text: 'Сертификаты для работы с персональными данными, ГИС,КИИ и финансовыми операциями.',
    },
    {
      icon: DatacenterHostSVG,
      title: 'Защищенные ЦОД',
      text: 'Шесть дата-центров с уровнем надежности Tier III и SLA 99,982%. Современные серверы и последние версии ПО.',
    },
    {
      icon: HeadphonesMicrophoneSVG,
      title: 'В поддержке люди, а не боты',
      text: 'Круглосуточная связь с поддержкой по телефону, почте и в Telegram. Среднее время реакции — 15 минут.',
    },
    {
      icon: AdminSVG,
      title: 'Команда опытных разработчиков',
      text: '1 400 специалистов работают над запуском наших собственных IaaS- и PaaS-сервисов.',
    },
  ],
};

const BENEFITS_DOCS = {
  title: 'Начните с полезных разделов',
  items: [
    {
      icon: FileSVG,
      title: 'Документация',
      text: 'Обширная и детализированная документация поможет вам легко ориентироваться в продуктах и услугах',
      slug: '/docs/index.html',
    },
    {
      icon: RubleSVG,
      title: 'Цены',
      text: 'С помощью калькулятора вы сможете точно рассчитать необходимые мощности и оптимизировать свои затраты',
      slug: '/calculator',
    },
  ],
};

const CAROUSEL_DATA = {
  title: 'Компании, которые решили свою задачу с Cloud.ru',
  slides: [
    {
      text: 'Время выгрузки отчета в 1С сократилось в 2 раза. ',
      image:
        'https://cdn.cloud.ru/backend/solutions/vse-programmy-1s-na-serverah-cloud/case_1.svg',
      link: '',
    },
    {
      text: 'Перенос в облако «1C:ERP» и «1С:ЗУП» снизил нагрузку на&nbsp;техподдержку',
      image:
        'https://cdn.cloud.ru/backend/solutions/vse-programmy-1s-na-serverah-cloud/case_2.png',
      link: '',
    },

    {
      text: 'Данные в «1C:ERP»  стали загружаться быстрее на 5-6 часов.',
      image:
        'https://cdn.cloud.ru/backend/solutions/vse-programmy-1s-na-serverah-cloud/case_3.png',
      link: '',
    },
    {
      text: 'Миграция данных 1С в облако реализована за 24 часа. Все&nbsp;сервисы работают стабильно, в то время как количество данных превышает 1 ТБ.',
      image:
        'https://cdn.cloud.ru/backend/solutions/vse-programmy-1s-na-serverah-cloud/case_4.png',
      link: '',
    },
    {
      text: 'Бизнес-процессы в 1С:Документооборот» ускорились в несколько раз.',
      image:
        'https://cdn.cloud.ru/backend/solutions/vse-programmy-1s-na-serverah-cloud/case_5.png',
      link: '',
    },
    {
      text: 'Повысилась доступность всех учетных систем — «1C:ERP», «1С:ЗУП» и «1С:Бухгалтерия».',
      image:
        'https://cdn.cloud.ru/backend/solutions/vse-programmy-1s-na-serverah-cloud/case_6.png',
      link: '',
    },
  ],
};

const TABS_DATA = {
  title: 'Удобный личный кабинет',
  tabs: [
    {
      id: '1',
      title: 'Создание инфраструктуры',
    },
    {
      id: '2',
      title: 'Контроль затрат',
    },
    {
      id: '3',
      title: 'Быстрая поддержка',
    },
  ],
  videos: [
    {
      id: '1',
      link: 'https://cdn-video.cloud.ru/backend/video/main-page/lk_evolution_compute.mp4',
      controls: true,
      previewImage:
        'https://cdn.cloud.ru/backend/video/main-page/lk_evolution_compute_preview.webp',
    },
    {
      id: '2',
      link: 'https://cdn-video.cloud.ru/backend/video/main-page/cost_control.mp4',
      controls: true,
      previewImage:
        'https://cdn.cloud.ru/backend/video/main-page/cost_control_preview.webp',
    },
    {
      id: '3',
      link: 'https://cdn-video.cloud.ru/backend/video/main-page/support.mp4',
      controls: true,
      previewImage:
        'https://cdn.cloud.ru/backend/video/main-page/support_preview.webp',
    },
  ],
  button: {
    title: 'В консоль',
    uniqueId: 'main-page-tabs-button',
  },
};

const TARIFF_TITLE = 'Начните с популярных сервисов';

const BUTTON_ID = '';

interface IndexProps {
  catalog: Catalog;
  carouselCases: ClientCasesProps[];
  mainSlider: MainSlideProps[];
}

export function IndexPage({ catalog, carouselCases, mainSlider }: IndexProps) {
  const [isConsultationFormVisible, setIsConsultationFormVisible] =
    useState(false);

  const router = useRouter();

  const consoleUrl = useFormatConsoleUrl(
    consoleRedirectUrl,
    'body',
    'В консоль',
  );

  return (
    <Layout>
      <Seo
        title="Cloud.ru - Российский облачный провайдер для развития бизнеса: IaaS/PaaS сервисы и ML платформа"
        description="Развивайте бизнес с надежным российским облачным провайдером Cloud.ru! Бесплатная миграция, масштабирование инфраструктуры, доступ к суперкомпьютеру, маркетплейс AI-сервисов, высокая устойчивость к пиковым нагрузкам, соответствие 152-ФЗ, 187-ФЗ, PCI DSS, ISO"
      >
        <meta
          key="og:title"
          property="og:title"
          content="Российский облачный провайдер для бизнеса: IaaS, PaaS и ML решения"
        />
        <meta
          key="og:description"
          property="og:description"
          content="Развивайте ваш бизнес с Cloud.ru — российским облачным провайдером, предлагающим IaaS и PaaS сервисы и передовую ML платформу. Безопасное соответствие всем стандартам и мощные инструменты для роста вашего бизнеса."
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(organizationMicroData),
          }}
        />
      </Seo>
      <div className={s.root}>
        <MainCarousel slides={mainSlider} />

        <TariffPlans tariffPlansType="mainPage" title={TARIFF_TITLE} />

        {catalog && (
          <CatalogBlock
            title="Продукты Cloud.ru"
            categories={catalog.categories}
            products={catalog.products}
            backgroundColor="neutral-background1-level"
          />
        )}

        <DemoSection
          type={DemoSection.type.Tabs}
          title={TABS_DATA.title}
          tabs={TABS_DATA.tabs}
          videos={TABS_DATA.videos}
          button={{
            title: TABS_DATA.button.title,
            uniqueId: TABS_DATA.button.uniqueId,
            onClick: () => router.push(consoleUrl),
          }}
        />

        {carouselCases && carouselCases.length > 0 && (
          <ClientCasesCarousel
            title={CAROUSEL_DATA.title}
            slides={carouselCases}
            moreButton={{
              page: 'main',
            }}
          />
        )}

        <Benefits
          className={s.benefits}
          title={BENEFITS.title}
          cards={BENEFITS.items}
          grid={Benefits.grid.ThreeColumns}
        />

        <Benefits
          className={s.benefits}
          title={BENEFITS_DOCS.title}
          cards={BENEFITS_DOCS.items}
          cardWithShadow
        />
      </div>
      {isConsultationFormVisible && (
        <Form
          consultationFormType="body"
          allFormsSentFormType="consultation"
          allFormsSentIsPopup
          tryFormType="consultation"
          title="Связаться с нашим специалистом"
          modalClose={() => setIsConsultationFormVisible(false)}
          inputPath="v2/order"
          additionalFields={{ subsegment: 'consultation' }}
          analyticsFieldFill={{
            formType: 'popup',
            popupPlace: 'body',
            buttonId: BUTTON_ID,
          }}
          formVariant="modal"
          successfulSubmissonFormAnalytics={{
            formname: 'consultation',
          }}
        />
      )}
    </Layout>
  );
}
