import { HeroSlideProps, HeroSlider } from '@sbercloud/uikit-product-site-hero';
import { useRouter } from 'next/navigation';
import { useLayoutType } from 'src/hooks/useLayoutType';
import { Color } from 'src/uikit/Button/constants';

import { consoleRedirectUrl } from '../../../global';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { useGetFormatConsoleUrl } from '../../../hooks/useFormatConsoleUrl';
import { CUSTOM_SLIDES_MAIN } from './constants';
import { mapColorToAppearance, sortSlidesByOrder } from './helpers';
import { MainSlideProps } from './types';

export type { MainSlideProps };

type MainCarouselProps = {
  slides: MainSlideProps[];
};

export function MainCarousel({ slides }: MainCarouselProps) {
  const router = useRouter();
  const layoutType = useLayoutType();
  const { clickAnalytics } = useAnalytics();

  const { getFormatConsoleUrl } = useGetFormatConsoleUrl();

  const slidesItems = sortSlidesByOrder(CUSTOM_SLIDES_MAIN, slides).map(
    (slide) => {
      const buttonHref = slide.button.consoleUrl
        ? getFormatConsoleUrl({
            url: consoleRedirectUrl,
            zoneclick: 'banner',
            elementText: slide.button.text || '',
          })
        : slide.button.link;

      const button: HeroSlideProps['button'] = {
        label: slide.button.text,
        href: buttonHref,
        onClick: (e) => {
          if (buttonHref && !e.metaKey) {
            e.preventDefault();
            router.push(buttonHref);
          }

          clickAnalytics({
            action: buttonHref ?? '',
            clickZone: 'body',
            clickElement: 'button',
            clickContent: button.label || 'подробнее',
            uniqueId:
              slide.customAnalyticsId || `main-slide-button-${slide.id}`,
            transitionType: 'inside-link',
          });
        },
      };

      const adaptiveImage = slide.image?.tablet || slide.image?.desktop || '';

      let media: HeroSlideProps['media'] = {
        type: 'image',
        format: slide.image?.format || 'rectangle',
        source: {
          desktop: slide.image?.desktop || '',
          tablet: adaptiveImage,
        },
      };

      if (slide.imageBg) {
        media = {
          type: 'imageBg',
          source: slide.imageBg,
        } as HeroSlideProps['media'] & { type: 'imageBg' };
      }

      if (slide.videoBg) {
        media = {
          type: 'videoBg',
          previewImage:
            slide.videoBg.previewImage ??
            'https://cdn.cloud.ru/backend/images/video-player/preview_default.png',
          link: slide.videoBg.link,
        } as HeroSlideProps['media'] & { type: 'videoBg' };
      }

      // TODO: [migration] можно будет удалить после замены данных в CMS
      const { appearance, color } = mapColorToAppearance(slide.theme);

      return {
        id: slide.id,
        title: slide.title,
        description: slide.description,
        media,
        button,
        appearance: slide.appearance || appearance,
        color: slide.color || color,
      } as HeroSlideProps;
    },
  );

  return (
    <HeroSlider
      layoutType={layoutType}
      items={slidesItems}
      autoSwipe={5}
      data-test-id="main-carousel"
    />
  );
}

MainCarousel.buttonColor = Color;
