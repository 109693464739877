import {
  SectionCatalog,
  SectionCatalogProps,
} from '@sbercloud/uikit-product-site-section';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { useLayoutType } from 'src/hooks/useLayoutType';
import { Catalog } from 'src/types/backendContent';

import { useAnalytics } from '../../hooks/useAnalytics';

interface CatalogBlockProps {
  id?: string;
  title: string;
  categories: Catalog['categories'];
  products: Catalog['products'];
  backgroundColor?: SectionCatalogProps['backgroundColor'];
}

export function CatalogBlock({
  id,
  title,
  categories,
  products,
  backgroundColor = 'neutral-background',
}: CatalogBlockProps) {
  const [activeCategory, setActiveCategory] = useState(categories[0].id);

  const router = useRouter();
  const { clickAnalytics } = useAnalytics();

  const layoutType = useLayoutType();

  const mapProductWithCategories = (): SectionCatalogProps['categories'] => {
    return categories.map((category) => {
      const items: SectionCatalogProps['categories'][number]['items'] = products
        .filter((product) =>
          product.categories?.find((cat) => cat.id === category?.id),
        )
        .map((product) => {
          const currentHref =
            product.type === 'products'
              ? `/products/${product.slug}`
              : `/solutions/${product.slug}`;

          const href = product.customLink ? product.customLink : currentHref;

          return {
            title: product.title,
            description: product.description,
            href,
            icon: {
              src: product.icon || '/plugs/product-2d-icon-default.svg',
              alt: product.title,
            },
            onClick: (e) => {
              clickAnalytics({
                action: href,
                clickZone: 'body',
                clickElement: 'cardclick',
                clickContent: product.title,
                uniqueId: `catalog-product-${product.title}`,
                transitionType: 'inside-link',
              });

              if (href.startsWith('/')) {
                e.preventDefault();
                router.push(href);
              }
            },
          };
        });

      return {
        id: category.id,
        label: category.title,
        items,
      };
    });
  };

  const footerLinks: SectionCatalogProps['footer'] = [
    {
      label: 'Все сервисы',
      href: '/products',
      onClick: (e) => {
        e.preventDefault();

        clickAnalytics({
          action: 'click',
          clickZone: 'body',
          clickElement: 'button',
          clickContent: 'Все сервисы',
          uniqueId: 'additional-links-products',
          transitionType: 'inside-link',
        });

        router.push('/products');
      },
    },
    {
      label: 'Маркетплейс',
      href: '/marketplace',
      onClick: (e) => {
        e.preventDefault();

        clickAnalytics({
          action: '/marketplace',
          clickZone: 'body',
          clickElement: 'button',
          clickContent: 'Маркетплейс',
          uniqueId: 'additional-links-marketplace',
          transitionType: 'inside-link',
        });

        router.push('/marketplace');
      },
    },
  ];

  return (
    <SectionCatalog
      id={id}
      title={title}
      categories={mapProductWithCategories()}
      onCategorySelect={setActiveCategory}
      selectedCategory={activeCategory}
      layoutType={layoutType}
      backgroundColor={backgroundColor}
      footer={footerLinks}
    />
  );
}
