import { CustomHeroSlide, MainSlideProps } from './types';

export function sortSlidesByOrder(
  customSlides: CustomHeroSlide[],
  dataSlides: MainSlideProps[],
): CustomHeroSlide[] {
  if (customSlides.length === 0) {
    return dataSlides;
  }

  const isOrder = customSlides.every((item) => Boolean(item.order));

  if (!isOrder) {
    return customSlides.concat(dataSlides);
  }

  const resultArray = [...dataSlides];

  const copyCustomSlides = customSlides.toSorted((a, b) => {
    if (a.order && b.order) {
      return parseInt(a.order, 10) - parseInt(b.order, 10);
    }

    return 0;
  });

  copyCustomSlides.forEach((item) => {
    if (item.order) {
      resultArray.splice(parseInt(item.order, 10), 0, item);
    }
  });

  return resultArray;
}

export function mapColorToAppearance(color: MainSlideProps['theme']) {
  switch (color) {
    case '#99c1ff': {
      return {
        color: 'blue',
        appearance: 'decor',
      };
    }
    case '#ffd7e0':
    case '#e1d7f2': {
      return {
        color: 'violet',
        appearance: 'decor',
      };
    }
    case '#f5ffbd': {
      return {
        color: 'white',
        appearance: 'decor',
      };
    }
    case '#26d07c': {
      return {
        appearance: 'brand',
      };
    }
    case '#222222': {
      return {
        appearance: 'graphite',
      };
    }
    case '#f6f6f6':
    default: {
      return {
        appearance: 'decor',
        color: 'neutral',
      };
    }
  }
}
